<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Create trunk
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout class="px-2">
          <v-flex xs8>
            <v-text-field
              ref="TrunkName"
              v-model="trunk.Description"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs4>
            <v-switch
              v-model="trunk.Active"
              label="Enabled"
            />
          </v-flex>
        </v-layout>

        <v-container class="px-2">
          <v-row>
            <v-col cols="5">
              <v-select
                v-model="trunk.TrunkType"
                flat
                :items="TrunkTypes"
                item-text="text"
                item-value="value"
                label="Trunk type"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <acl
                v-if="showACL"
                :acl="acl"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-expansion-panels
          focusable
          class="my-3 mx-2"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  settings_input_component
                </v-icon>Channels (Total: {{ trunk.Capabilities.LIMIT_MAX }})
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <v-layout>
                  <v-flex xs2>
                    <v-text-field
                      v-model.number="trunk.Capabilities.LIMIT_MAX"
                      type="number"
                      label="Total"
                      :rules="numberRules"
                      :counter="false"
                    />
                  </v-flex>
                  <v-flex
                    xs2
                    offset-xs1
                  >
                    <v-text-field
                      v-model.number="trunk.Capabilities.LIMIT_OUTBOUND"
                      type="number"
                      label="Max outbound"
                      :rules="numberRules"
                      :counter="false"
                    />
                  </v-flex>
                  <v-flex
                    xs2
                    offset-xs1
                  >
                    <v-text-field
                      v-model.number="trunk.Capabilities.LIMIT_INBOUND"
                      type="number"
                      label="Max inbound"
                      :rules="numberRules"
                      :counter="false"
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  call
                </v-icon>Calls
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <v-layout>
                  <v-flex xs12>
                    <v-switch
                      v-model="trunk.Capabilities.PERMIT_OUTBOUND"
                      label="Allow outbound calls"
                    />
                    <v-switch
                      v-model="trunk.Capabilities.PERMIT_INTERNATIONAL"
                      label="Allow international calls"
                    />
                    <v-tooltip
                      left
                      color="red"
                    >
                      <template #activator="{on}">
                        <v-switch
                          v-model="trunk.Capabilities.PERMIT_INTERNATIONAL_PREMIUM"
                          label="Allow international premium calls"
                          v-on="on"
                        />
                      </template>
                      <span>This may cause high costs on your invoice!</span>
                    </v-tooltip>
                    <v-switch
                      v-model="trunk.Capabilities.PERMIT_MOBILE"
                      label="Allow mobile calls"
                    />
                    <v-switch
                      v-model="trunk.Capabilities.PERMIT_PREMIUM"
                      label="Allow national premium calls"
                    />
                    <v-switch
                      v-model="trunk.Capabilities.PERMIT_NONGEO"
                      label="Allow nongeo calls"
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  ring_volume
                </v-icon>Number display
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <v-layout>
                  <v-flex xs12>
                    <v-switch
                      v-model="trunk.Capabilities.CLI_OVERRIDE"
                      label="Allow the phone system to send a different number"
                    />
                    <v-switch
                      v-model="trunk.Capabilities.CLI_WITHHOLD"
                      label="Do not send your phone number"
                    />
                    <v-switch
                      v-model="trunk.E164"
                      label="Use E.164"
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="subtitle-1">
              <v-icon class="mr-3">
                cloud_upload
              </v-icon>Engineering Info
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <!-- Profile and Country Code side by side -->
              <v-row dense>
                <v-col cols="6">
                  <v-select
                    v-model="trunk.Profile"
                    flat
                    :items="deploymentTypes"
                    label="Profile"
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="trunk.CountryCode"
                    :items="countryCodes"
                    label="Country Code"
                    item-text="text"
                    item-value="value"
                  />
                </v-col>
              </v-row>

              <!-- STUN Server (conditional) -->
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-if="trunk.Profile === 'internal'"
                    v-model="trunk.StunServer"
                    flat
                    label="STUN"
                  />
                </v-col>
              </v-row>

              <!-- Audio Codec List -->
              <v-row dense>
                <v-col>
                  <v-select
                    v-model="trunk.AudioCodecList"
                    :items="codecs"
                    label="Audio Codec List"
                    multiple
                    chips
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels>

        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="createTrunk"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$router.push('/trunks')"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import config from '@/config.js';

import acl from '@/components/acl/acl';

export default {
  name: 'Newtrunk',
  components: {
    acl,
  },
  data() {
    return {
      valid: false,
      trunk: {
        Description: '',
        Profile: 'btlcloud',
        CountryCode: 'GB', // Default country code
        Capabilities: {
          CLI_CHANGE_CUSTOM: false,
          CLI_CHANGE_DDI: false,
          CLI_CHANGE_UNRESTRICTED: false,
          CLI_HAS_DDILIMITS: false,
          CLI_OVERRIDE: true,
          CLI_REQUEST_OVERRIDE: false,
          CLI_WITHHOLD: false,
          DISABLED: false,
          HAS_BLACKLIST: false,
          HAS_WHITELIST: false,
          LIMIT_INBOUND: 5,
          LIMIT_MAX: 5,
          LIMIT_OUTBOUND: 5,
          PERMIT_FAX: false,
          PERMIT_INBOUND: true,
          PERMIT_INTERNATIONAL: true,
          PERMIT_INTERNATIONAL_PREMIUM: false,
          PERMIT_MOBILE: true,
          PERMIT_NONGEO: true,
          PERMIT_OUTBOUND: true,
          PERMIT_PREMIUM: true,
          PERMIT_SPECIAL: false,
        },
        TrunkType: 'With Credentials',
        Active: true,
        SkypeTrunk: false,
        E164: false,
        StunServer: 'stun.btlcloud.net',
      },
      codecs: [
        'PCMA', 'PCMU', 'G729', 'VP8', 'OPUS', 'G722',
      ],
      acl: [],
      deploymentTypes: [
        { value: 'btlcloud', text: 'BTL-Cloud' },
        { value: 'internal', text: 'Internet' },
      ],
      TrunkTypes: [
        { value: 'With Credentials', text: 'With Credentials' },
        { value: 'Without Credentials/ACL', text: 'Without Credentials/ACL' },
        { value: 'Skype for Business/ACL', text: 'Skype for Business/ACL' },
        { value: 'Five9', text: 'Five9' },
      ],
      countryCodes: [
        { value: 'GB', text: 'United Kingdom (GB)' },
        { value: 'IE', text: 'Ireland (IE)' },
      ],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
      numberRules: [
        (v) => (v && parseInt(v, 10) > 0) || 'Number must be greater than 0',
        (v) => (v && parseInt(v, 10) <= this.trunk.Capabilities.LIMIT_MAX) || 'Number must be smaller/equal to total number',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
    showACL() {
      return this.trunk.TrunkType !== 'With Credentials' && this.trunk.TrunkType !== 'Five9';
    },
  },
  watch: {
    'trunk.Capabilities.LIMIT_MAX': function (channelTotal) {
      this.trunk.Capabilities.LIMIT_OUTBOUND = channelTotal;
      this.trunk.Capabilities.LIMIT_INBOUND = channelTotal;
    },
    selectedAccountId() {
      this.$router.back();
    },
  },
  mounted() {
    this.$refs.TrunkName.focus();
  },
  methods: {
    createTrunk() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.$emit('snack', 'Correct invalid fields before saving.', true);
        return;
      }

      if (this.acl.length > 0) {
        this.trunk.DirectDetails = '';
        this.acl.forEach((l, i) => {
          this.trunk.DirectDetails = this.trunk.DirectDetails.concat(l.ip).concat(':').concat(l.port).concat(':')
            .concat(l.protocol);
          if ((i + 1) < this.acl.length) this.trunk.DirectDetails = this.trunk.DirectDetails.concat(';');
        });
      } else {
        this.trunk.DirectDetails = null;
      }

      axios.post(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/trunks`, this.trunk)
        .then(() => {
          this.$emit('snack', 'Trunk successfully created!');
          this.$router.push('/trunks');
        })
        .catch((error) => {
          this.$emit('snack', 'Failed to create trunk.', true);
          console.error('ERROR', error.response);
        });
    },
  },
};
</script>

<style scoped>
</style>
