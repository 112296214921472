<template>
  <div>
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Edit trunk
      </v-toolbar-title>
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/accounts/<account_id>/trunks/<trunkid>', 'DELETE')"
        text
        color="blue"
        @click="delDialog=true"
      >
        <v-icon class="mr-2">
          delete
        </v-icon>Delete
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-layout class="px-2">
          <v-flex xs8>
            <v-text-field
              v-model="trunk.Description"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <v-layout class="px-2">
          <v-flex xs4>
            <v-switch
              v-model="trunk.Active"
              label="Enabled"
            />
          </v-flex>
        </v-layout>

        <v-container class="px-2">
          <v-row>
            <v-col cols="5">
              <v-select
                v-model="trunk.TrunkType"
                flat
                :items="TrunkTypes"
                item-text="text"
                item-value="value"
                label="Trunk type"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <acl
                v-if="showACL"
                :acl="acl"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-expansion-panels
          v-if="trunk.Capabilities"
          focusable
          class="my-3 mx-2"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  info
                </v-icon>Trunk Details
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field
                v-model="trunk.Username"
                label="Username"
                class="mt-3"
                readonly
              />
              <v-text-field
                v-model="trunk.Password"
                label="Password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                readonly
                @click:append="showPassword = !showPassword"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  settings_input_component
                </v-icon>Channels (Total: {{ trunk.Capabilities.LIMIT_MAX }})
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <v-layout>
                  <v-flex xs3>
                    <v-text-field
                      v-model.number="trunk.Capabilities.LIMIT_MAX"
                      type="number"
                      label="Total"
                      :rules="numberRules"
                      :counter="false"
                      @blur="adjustLimits"
                    />
                  </v-flex>
                  <v-flex
                    xs3
                    offset-xs1
                  >
                    <v-text-field
                      v-model.number="trunk.Capabilities.LIMIT_OUTBOUND"
                      type="number"
                      label="Max outbound"
                      :rules="numberRules"
                      :counter="false"
                    />
                  </v-flex>
                  <v-flex
                    xs3
                    offset-xs1
                  >
                    <v-text-field
                      v-model.number="trunk.Capabilities.LIMIT_INBOUND"
                      type="number"
                      label="Max inbound"
                      :rules="numberRules"
                      :counter="false"
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  call
                </v-icon>Calls
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <v-layout>
                  <v-flex xs12>
                    <v-switch
                      v-model="trunk.Capabilities.PERMIT_OUTBOUND"
                      label="Allow outbound calls"
                    />
                    <v-switch
                      v-model="trunk.Capabilities.PERMIT_INTERNATIONAL"
                      label="Allow international calls"
                    />
                    <v-tooltip
                      left
                      color="red"
                    >
                      <template #activator="{on}">
                        <v-switch
                          v-model="trunk.Capabilities.PERMIT_INTERNATIONAL_PREMIUM"
                          label="Allow international premium calls"
                          v-on="on"
                        />
                      </template>
                      <span>This may cause high costs on your invoice!</span>
                    </v-tooltip>
                    <v-switch
                      v-model="trunk.Capabilities.PERMIT_MOBILE"
                      label="Allow mobile calls"
                    />
                    <v-switch
                      v-model="trunk.Capabilities.PERMIT_PREMIUM"
                      label="Allow national premium calls"
                    />
                    <v-switch
                      v-model="trunk.Capabilities.PERMIT_NONGEO"
                      label="Allow nongeo calls"
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="subtitle-1">
                <v-icon class="mr-3">
                  ring_volume
                </v-icon>Number display
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="px-5 mb-3">
                <v-layout>
                  <v-flex xs12>
                    <v-switch
                      v-model="trunk.Capabilities.CLI_OVERRIDE"
                      label="Allow the phone system to send a different number"
                    />
                    <v-switch
                      v-model="trunk.Capabilities.CLI_WITHHOLD"
                      label="Do not send your phone number"
                    />
                    <v-switch
                      v-model="trunk.E164"
                      label="Use E.164"
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="subtitle-1">
              <v-icon class="mr-3">
                cloud_upload
              </v-icon>Engineering Info
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <!-- Profile and Country Code side by side -->
              <v-row dense>
                <v-col cols="6">
                  <v-select
                    v-model="deploymentType"
                    flat
                    :items="deploymentTypes"
                    item-text="text"
                    item-value="value"
                    label="Profile"
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="trunk.CountryCode"
                    :items="countryCodes"
                    label="Country Code"
                    item-text="text"
                    item-value="value"
                  />
                </v-col>
              </v-row>

              <!-- STUN Server (conditional) -->
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-if="deploymentType === 'INTERNET'"
                    v-model="trunk.StunServer"
                    flat
                    label="STUN"
                  />
                </v-col>
              </v-row>

              <!-- Location -->
              <v-row dense>
                <v-col>
                  <v-text-field
                    label="Location"
                    :value="trunk.Location"
                    disabled
                  />
                </v-col>
              </v-row>

              <!-- Audio Codec List -->
              <v-row dense>
                <v-col>
                  <v-select
                    v-model="trunk.AudioCodecList"
                    :items="codecs"
                    label="Audio Codec List"
                    multiple
                    chips
                    deletable-chips
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels>
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            @click="updateTrunk"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$router.push('/trunks')"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
      <v-dialog
        v-model="delDialog"
        persistent
        max-width="300"
      >
        <v-card>
          <v-card-title class="headline">
            Delete Trunk
          </v-card-title>
          <v-container>
            <v-layout class="px-2">
              <v-flex xs12>
                <p class="subtitle-1">
                  Do you really want to delete this Trunk?
                </p>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              primary
              color="primary"
              dark
              @click="deleteTrunk"
            >
              Delete
            </v-btn>
            <v-btn
              text
              color="grey"
              @click="delDialog=false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import config from '@/config.js';
import acl from '@/components/acl/acl';
import permittedFunction from '@/permittedFunction.js';

export default {
  name: 'TrunkCRUD',
  components: {
    acl,
  },
  data() {
    return {
      delDialog: false,
      trunkId: this.$route.params.id,
      valid: false,
      acl: [],
      deploymentType: 'BTL',
      deploymentTypes: [
        { value: 'BTL', text: 'BTL-Cloud' },
        { value: 'INTERNET', text: 'Internet' },
      ],
      TrunkTypes: [
        { value: 'With Credentials', text: 'With Credentials' },
        { value: 'Without Credentials/ACL', text: 'Without Credentials/ACL' },
        { value: 'Skype for Business/ACL', text: 'Skype for Business/ACL' },
        { value: 'Five9', text: 'Five9' },
      ],
      codecs: [
        'PCMA', 'PCMU', 'G729', 'VP8', 'OPUS', 'G722',
      ],
      trunk: {
        Description: '',
        TrunkType: 'Default',
        CountryCode: 'GB', // Default country code
      },
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
      numberRules: [
        (v) => (v && parseInt(v, 10) > 0) || 'Number must be greater than 0',
        (v) => (v && parseInt(v, 10) <= this.trunk.Capabilities.LIMIT_MAX) || 'Number must be smaller/equal to total number',
      ],
      showPassword: false,
      countryCodes: [
        { value: 'GB', text: 'United Kingdom (GB)' },
        { value: 'IE', text: 'Ireland (IE)' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
    showACL() {
      return this.trunk.TrunkType !== 'With Credentials' && this.trunk.TrunkType !== 'Five9';
    },
  },
  watch: {
    selectedAccountId() {
      this.$router.back();
    },
  },
  created() {
    this.loadTrunk();
  },
  methods: {
    adjustLimits() {
      if (this.trunk.Capabilities.LIMIT_OUTBOUND > this.trunk.Capabilities.LIMIT_MAX) {
        this.trunk.Capabilities.LIMIT_OUTBOUND = this.trunk.Capabilities.LIMIT_MAX;
      }
      if (this.trunk.Capabilities.LIMIT_INBOUND > this.trunk.Capabilities.LIMIT_MAX) {
        this.trunk.Capabilities.LIMIT_INBOUND = this.trunk.Capabilities.LIMIT_MAX;
      }
    },
    updateTrunk() {
      if (this.valid === false) {
        this.$emit('snack', 'Correct invalid fields before saving.', true);
        return;
      }

      // Remove the Username field before sending the request
      delete this.trunk.Username;

      // Handle ACL details
      if (this.acl.length > 0) {
        this.trunk.DirectDetails = '';
        this.acl.forEach((l, i) => {
          this.trunk.DirectDetails = this.trunk.DirectDetails.concat(l.ip).concat(':').concat(l.port).concat(':')
            .concat(l.protocol);
          if ((i + 1) < this.acl.length) this.trunk.DirectDetails = this.trunk.DirectDetails.concat(';');
        });
      } else {
        this.trunk.DirectDetails = null;
      }

      // Handle deployment type and STUN state
      this.trunk.Profile = this.deploymentType === 'INTERNET' ? 'internal' : 'btlcloud';
      this.trunk.StunState = this.deploymentType === 'INTERNET';
      this.trunk.SkypeTrunk = this.trunk.TrunkType === 'Skype for Business/ACL';

      // Ensure AudioCodecList is either a non-empty array or null
      if (this.trunk.AudioCodecList && this.trunk.AudioCodecList.length === 0) {
        this.trunk.AudioCodecList = null;
      }

      // Send the updated trunk data to the backend
      axios.put(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/trunks/${this.trunkId}`, this.trunk)
        .then(() => {
          this.$emit('snack', 'Trunk successfully updated!');
          this.$router.push('/trunks');
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    deleteTrunk() {
      this.delDialog = false;
      axios.delete(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/trunks/${this.trunkId}`)
        .then(() => {
          this.$emit('snack', 'Trunk successfully deleted!');
          this.$router.push('/trunks');
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    loadTrunk() {
      const self = this;
      axios.get(`${config.BTL_API_TENANT_URL}/accounts/${self.selectedAccountId}/trunks/${this.trunkId}`)
        .then((response) => {
          self.trunk = response.data;
          self.deploymentType = self.trunk.StunState ? 'INTERNET' : 'BTL';
          self.acl = [];
          if (self.trunk.DirectDetails) {
            const listArray = self.trunk.DirectDetails.split(';');
            listArray.forEach((l) => {
              const ipArray = l.split(':');
              const ip = {
                ip: ipArray[0],
                port: ipArray[1],
                protocol: ipArray[2],
              };
              self.acl.push(ip);
            });
          }
          // Set default country code if not provided
          if (!self.trunk.CountryCode) {
            self.trunk.CountryCode = 'GB';
          }
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
  },
};
</script>

<style scoped>
</style>
