<template>
  <div class="flex-wrapper">
    <v-toolbar flat>
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-toolbar-title class="mr-5">
        Create CallFlow
      </v-toolbar-title>
      <v-btn
        color="primary"
        text
        @click="savePDF"
      >
        Export as PDF
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <v-container fluid>
      <v-layout class="px-2">
        <v-flex xs7>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-text-field
              ref="CallFlowName"
              v-model="callflow.Name"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
            <v-switch
              v-model="callflow.Enabled"
              label="Enabled"
            />
          </v-form>
        </v-flex>
      </v-layout>
      <v-layout class="px-2 mt-3">
        <p class="subtitle-1 mt-2 mr-5">
          Dial plan
        </p>
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="createCallflow"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$router.back()"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-layout>
      <v-layout class="growme">
        <CallFlowEditor
          id="callFlowComponent"
          :callflow="callflow.Dialplan"
        />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import * as html2canvas from 'html2canvas';
import JsPDF from 'jspdf';

import config from '@/config.js';

import CallFlowEditor from '@/components/callfloweditor2/CallFlowEditor';

export default {
  name: 'Newcallflow',
  components: {
    CallFlowEditor,
  },
  data() {
    return {
      valid: false,
      callflow: {
        Name: '',
        Enabled: false,
      },
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'selectedAccountId',
    ]),
  },
  watch: {
    selectedAccountId() {
      this.$router.back();
    },
  },
  created() {
    this.callflow = this.generateNewCallflow();
  },
  mounted() {
    this.valid = false;
    this.$refs.CallFlowName.focus();
  },
  methods: {
    validForm() {
      if (this.callflow.Name === '') {
        this.$emit('snack', 'CallFlow name is a required field!');
        return false;
      }
      // All good and valid
      return true;
    },
    savePDF() {
      const doc = new JsPDF({
        orientation: 'landscape',
      });
      const el = document.getElementById('callFlowComponent');
      const _self = this;
      html2canvas(el, {
        scale: 1.0,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png', 1.0);
        const filename = `${_self.callflow.Name}-${new Date().toJSON().split('T')[0]}.pdf`;
        doc.addImage(imgData, 'PNG', 15, 20);
        doc.text(filename.split('.')[0], 15, 10);
        doc.autoPrint(); // this works when pdf is opened in viewer
        doc.save(filename);
      });
    },
    createCallflow() {
      if (this.validForm()) {
        axios.post(`${config.bizvu_callflow_url}/account/${this.selectedAccountId}/callflow/create/version`, this.callflow)
          .then(() => {
            this.$emit('snack', 'CallFlow successfully created!');
            this.$router.back();
          })
          .catch((error) => {
            if (error.response.data.ValidationReport) {
              const errors = [
                ...error.response.data.ValidationReport.Error,
                ...error.response.data.ValidationReport.Critical,
                ...error.response.data.ValidationReport.Warning,
              ].map((e) => ({
                text: e.text,
                isError: true,
              }));
              this.$emit('snack', errors, true);
            } else {
              this.$emit('snack', 'Failed to save CallFlow', true);
            }
          });
      }
    },
    generateNewCallflow() {
      // FIXME: This needs to go into account settings (configurable callflow templated per account)
      // FIXME: Part of the phase 2 backlog!!!
      if (this.$route.params.templateId === 'default') {
        return {
          Name: '',
          Enabled: false,
          Dialplan: [
            {
              action: 'Start',
              id: 0,
              layout: {
                y: 225,
                x: 0,
              },
              name: 'Beginning',
              next: 99,
            },
            {
              action: 'Finish',
              layout: {
                y: 300,
                x: 300,
              },
              name: 'End',
              id: 99,
            },
          ],
        };
      } if (this.$route.params.templateId === 'template1') {
        return {
          Name: '',
          Enabled: false,
          Dialplan: [{
            layout: {
              y: 225,
              x: 0,
            },
            next: 100,
            action: 'Start',
            name: 'Beginning',
            id: 0,
          }, {
            layout: {
              y: 300,
              x: 300,
            },
            action: 'Finish',
            name: 'End',
            id: 99,
          }, {
            layout: {
              y: 200,
              x: 175,
            },
            parameters: {
              phonenumber: '+441234567890',
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {},
            },
            next: 99,
            action: 'DialNumber',
            name: 'Dial number',
            id: 100,
          }],
        };
      } if (this.$route.params.templateId === 'template2') {
        return {
          Name: '',
          Enabled: false,
          Dialplan: [{
            layout: {
              y: 225,
              x: 0,
            },
            next: 100,
            action: 'Start',
            name: 'Beginning',
            id: 0,
          }, {
            layout: {
              y: 300,
              x: 300,
            },
            action: 'Finish',
            name: 'End',
            id: 99,
          }, {
            layout: {
              y: 200,
              x: 175,
            },
            parameters: {
              siteid: '33940def-8929-455b-917f-76d56b1339c6',
            },
            conditions: {
              SiteDR: {
                True: 101,
                False: 102,
              },
            },
            next: 99,
            action: 'SiteDR',
            name: 'Check DR Active',
            id: 100,
          }, {
            layout: {
              y: 125,
              x: 350,
            },
            parameters: {
              phonenumber: '+441234567890',
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {},
            },
            next: 99,
            action: 'DialNumber',
            name: 'Dial number',
            id: 101,
          }, {
            layout: {
              y: 300,
              x: 350,
            },
            parameters: {
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {
                FAILOVER: 103,
              },
            },
            next: 99,
            action: 'DialTrunk',
            name: 'Route to Trunk',
            id: 102,
          }, {
            layout: {
              y: 300,
              x: 550,
            },
            parameters: {
              phonenumber: '+441234567890',
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {},
            },
            next: 99,
            action: 'DialNumber',
            name: 'Dial number',
            id: 103,
          }],
        };
      } if (this.$route.params.templateId === 'template3') {
        return {
          Name: '',
          Enabled: false,
          Dialplan: [{
            layout: {
              y: 225,
              x: 0,
            },
            next: 100,
            action: 'Start',
            name: 'Beginning',
            id: 0,
          }, {
            layout: {
              y: 300,
              x: 300,
            },
            action: 'Finish',
            name: 'End',
            id: 99,
          }, {
            layout: {
              y: 200,
              x: 175,
            },
            parameters: {
              siteid: '33940def-8929-455b-917f-76d56b1339c6',
            },
            conditions: {
              SiteDR: {
                True: 101,
                False: 102,
              },
            },
            next: 99,
            action: 'SiteDR',
            name: 'Check DR Active',
            id: 100,
          }, {
            layout: {
              y: 125,
              x: 350,
            },
            parameters: {
              phonenumber: '+441234567890',
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {},
            },
            next: 99,
            action: 'DialNumber',
            name: 'Dial number',
            id: 101,
          }, {
            layout: {
              y: 300,
              x: 350,
            },
            parameters: {
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {
                FAILOVER: 103,
              },
            },
            next: 99,
            action: 'DialTrunk',
            name: 'Route to Trunk',
            id: 102,
          }, {
            layout: {
              y: 300,
              x: 600,
            },
            parameters: {
              trunkid: 'a0i77rpy',
            },
            conditions: {
              DIALSTATUS: {
                FAILOVER: 104,
              },
            },
            next: 99,
            action: 'DialTrunk',
            name: 'Route to Trunk',
            id: 103,
          }, {
            layout: {
              y: 300,
              x: 825,
            },
            parameters: {
              phonenumber: '+441234567890',
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {},
            },
            next: 99,
            action: 'DialNumber',
            name: 'Dial number',
            id: 104,
          }],
        };
      } if (this.$route.params.templateId === 'template4') {
        return {
          Name: '',
          Enabled: false,
          Dialplan: [{
            layout: {
              y: 225,
              x: 0,
            },
            next: 100,
            action: 'Start',
            name: 'Beginning',
            id: 0,
          }, {
            layout: {
              y: 300,
              x: 300,
            },
            action: 'Finish',
            name: 'End',
            id: 99,
          }, {
            layout: {
              y: 200,
              x: 175,
            },
            parameters: {
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {
                FAILOVER: 101,
              },
            },
            next: 99,
            action: 'DialTrunk',
            name: 'Route to Trunk',
            id: 100,
          }, {
            layout: {
              y: 200,
              x: 475,
            },
            parameters: {
              phonenumber: '+441234567890',
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {},
            },
            next: 99,
            action: 'DialNumber',
            name: 'Dial number',
            id: 101,
          }],
        };
      } if (this.$route.params.templateId === 'template5') {
        return {
          Name: '',
          Enabled: false,
          Dialplan: [{
            layout: {
              y: 225,
              x: 0,
            },
            next: 100,
            action: 'Start',
            name: 'Beginning',
            id: 0,
          }, {
            layout: {
              y: 300,
              x: 300,
            },
            action: 'Finish',
            name: 'End',
            id: 99,
          }, {
            layout: {
              y: 200,
              x: 175,
            },
            parameters: {
              siteid: '33940def-8929-455b-917f-76d56b1339c6',
              exceptions: {},
              workinghours: {},
            },
            conditions: {
              workinghours: {
                True: 101,
                False: 102,
              },
            },
            next: 99,
            action: 'CheckSiteWorkinghours',
            name: 'Check Workinghours',
            id: 100,
          }, {
            layout: {
              y: 125,
              x: 325,
            },
            parameters: {
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {
                FAILOVER: 103,
              },
            },
            next: 99,
            action: 'DialTrunk',
            name: 'Route to Trunk',
            id: 101,
          }, {
            layout: {
              y: 300,
              x: 325,
            },
            parameters: {
              phonenumber: '+441234567890',
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {},
            },
            next: 99,
            action: 'DialNumber',
            name: 'Dial number',
            id: 102,
          }, {
            layout: {
              y: 125,
              x: 525,
            },
            parameters: {
              phonenumber: '+441234567890',
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {},
            },
            next: 99,
            action: 'DialNumber',
            name: 'Dial number',
            id: 103,
          }],
        };
      } if (this.$route.params.templateId === 'template6') {
        return {
          Name: '',
          Enabled: false,
          Dialplan: [{
            layout: {
              y: 225,
              x: 0,
            },
            next: 100,
            action: 'Start',
            name: 'Beginning',
            id: 0,
          }, {
            layout: {
              y: 300,
              x: 300,
            },
            action: 'Finish',
            name: 'End',
            id: 99,
          }, {
            layout: {
              y: 198,
              x: 160,
            },
            parameters: {
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {
                FAILOVER: 101,
              },
            },
            next: 99,
            action: 'DialTrunk',
            name: 'Route to Trunk',
            id: 100,
          }, {
            layout: {
              y: 150,
              x: 375,
            },
            parameters: {
              trunkid: 'a0i77rpy',
            },
            conditions: {
              DIALSTATUS: {
                FAILOVER: 102,
              },
            },
            next: 99,
            action: 'DialTrunk',
            name: 'Route to Trunk',
            id: 101,
          }, {
            layout: {
              y: 150,
              x: 600,
            },
            parameters: {
              phonenumber: '+441234567890',
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {},
            },
            next: 99,
            action: 'DialNumber',
            name: 'Dial number',
            id: 102,
          }],
        };
      } if (this.$route.params.templateId === 'template7') {
        return {
          Name: '',
          Enabled: false,
          Dialplan: [{
            layout: {
              y: 225,
              x: 0,
            },
            next: 100,
            action: 'Start',
            name: 'Beginning',
            id: 0,
          }, {
            layout: {
              y: 300,
              x: 300,
            },
            action: 'Finish',
            name: 'End',
            id: 99,
          }, {
            layout: {
              y: 198,
              x: 160,
            },
            parameters: {
              siteid: '33940def-8929-455b-917f-76d56b1339c6',
              exceptions: {},
              workinghours: {},
            },
            conditions: {
              workinghours: {
                True: 101,
                False: 102,
              },
            },
            next: 99,
            action: 'CheckSiteWorkinghours',
            name: 'Check Workinghours',
            id: 100,
          }, {
            layout: {
              y: 121,
              x: 320,
            },
            parameters: {
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {
                FAILOVER: 103,
              },
            },
            next: 99,
            action: 'DialTrunk',
            name: 'Route to Trunk',
            id: 101,
          }, {
            layout: {
              y: 305,
              x: 320,
            },
            parameters: {
              phonenumber: '+441234567890',
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {},
            },
            next: 99,
            action: 'DialNumber',
            name: 'Dial number',
            id: 102,
          }, {
            layout: {
              y: 125,
              x: 550,
            },
            parameters: {
              trunkid: 'a0i77rpy',
            },
            conditions: {
              DIALSTATUS: {
                FAILOVER: 104,
              },
            },
            next: 99,
            action: 'DialTrunk',
            name: 'Route to Trunk',
            id: 103,
          }, {
            layout: {
              y: 125,
              x: 750,
            },
            parameters: {
              phonenumber: '+441234567890',
              trunkid: 'slsmb52l',
            },
            conditions: {
              DIALSTATUS: {},
            },
            next: 99,
            action: 'DialNumber',
            name: 'Dial number',
            id: 104,
          }],
        };
      }
      throw new Error('No template');
    },
  },
};
</script>

<style>
  .flex-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .flex-wrapper > header {
    flex-grow: 0;
  }

  .flex-wrapper > .container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .flex-wrapper > .container > .layout {
    flex-grow: 0;
  }

  .flex-wrapper > .container > .growme {
    flex-grow: 1;
  }
</style>
